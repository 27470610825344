import VueI18n from 'vue-i18n'

// import zh from './langs/zh'
// import en from './langs/en'
import Vue from 'vue'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale:  localStorage.getItem('lang') ||'en',
    // localStorage.getItem('lang') ||
    silentTranslationWarn: true, // 去除国际化警告
    //获取messages对象
    messages: {
        //中文翻译包
        'zh': {
            // 首页
            "logoPhone": "哆啦哆科技",
            "logoPhoneText": "深圳市哆啦哆科技有限公司",
            "index": "首页",
            "ClientCase": "客户案例",
            "ServiceItem": "服务项目",
            "AboutUs": "关于我们",
            "ContactItem": "联系我们",
            "MrLuo": "罗先生",
            "Language": "选择语言",
            "Language2": "切换语言",

            "bannerTitle": "软件定制开发",
            "bannerContent": "帮助企业数字化转型升级",

            "sidebox": "在线咨询",

            "fd-1-1": "关于我们",
            "fd-1-2": "我们是谁",
            "fd-1-3": "我们服务的客户",
            "fd-1-4": "我们的团队",

            "fd-2-1": "我们的服务",
            "fd-2-2": "网站建设",
            "fd-2-3": "H5响应式 交互网站",
            "fd-2-4": "移动端 & 微网站定制",
            "fd-2-5": "服务流程",
            "fd-2-6": "行业解决方案",

            "fd-3-1": "我们的案例",
            "fd-3-2": "蓝牙设备对接",
            "fd-3-3": "Wi-Fi设备对接",
            "fd-3-4": "户外报警器定制",
            "fd-3-5": "律所积分系统",
            "fd-3-6": "绘本点读系列",
            "fd-3-7": "数字农业",
            "fd-3-8": "分销商城",
            "fd-3-9": "贷款金融",
            "fd-3-10": "硬件对接",
            "fd-3-11": "生活服务",
            "fd-3-12": "高级定制",
            "fd-3-13": "政府机构",
            "fd-3-14": "教育培训",

            "fd-4-1": "深圳市哆啦哆科技有限公司",
            "fd-4-2": "技术负责人：",
            "fd-4-3": "扫一扫了解更多",
            "fd-4-4": " Copyright © 2013-2025 深圳市哆啦哆科技有限公司 版权所有粤ICP备2024261444号",

            "fd-4-5": "Copyright©2013-2025",
            "fd-4-6": "深圳市哆啦哆科技有限公司版权所有",
            "fd-4-7": "粤ICP备2024261444号",



            //HomePage
            "1": "互联网定制",
            "2": "APP",
            "3": "小程序",
            "4": "网站",
            "5": "WP网站定制",
            "6": "蓝牙电池",
            "7": "蓝牙遥控器",
            "8": "蓝牙点读笔",
            "9": "报警器设备",
            "10": "点读笔",
            "11": "扫描笔",
            "12": "手写笔",
            "13": "绘本指读",
            "14": "绘本翻读",

            "Main business": "主要业务",

            "bussiness1": "手机APP定制开发",
            "bussiness2": "小程序行业解决方案",
            "bussiness3": "微信公众平台开发",
            "bussiness4": "蓝牙/Wi-Fi/硬件对接",

            "brief1": "支持IOS开发、Android开发可量身定制软件版面及功能，应用市场上架",
            "brief2": "致力于帮助更多的中小企业开展线上线下业务，实现互联网数字化转型",
            "brief3": "支持企业办公、设备管理等系统开发",
            "brief4": "蓝牙电池/蓝牙遥控器/蓝牙点读笔、报警器设备、点读笔/扫描笔/手写笔/指读/翻读",

            "bussinessBottom1": "公众号/小程序/APP/蓝牙/Wi-Fi/硬件对接",
            "bussinessBottom2": "专注企业定制开发   打破企业发展瓶颈    帮助企业数字化转型升级",
            "bussinessBottom3": "了解更多",

            "pro1": "移动商城解决方案",
            "pro2": "营销活动软件解决方案",
            "pro3": "线上预约买课解决方案",
            "pro4": "软硬件交互解决方案",
            "pro5": "视频直播课程解决方案",
            "pro6": "互联网金融解决方案",
            "pro7": "企业培训解决方案",
            "pro8": "更多解决方案",

            "peoBrief1": "微信商城 小程序商城 分销商城 全民代理商城 个性化多样化等商城 专业完整的解决方案",
            "peoBrief2": "拼团 砍价 答题 红包 刮奖 优惠券 投票等常见及定制化营销软件让企业的系统如虎添翼",
            "peoBrief3": "美容 健身 课程培训 单/多店 一站式购买套餐 预约导师/技师/老师 根据企业工作流程量身定制移动端用户软件",
            "peoBrief4": "通过蓝牙/互联网让移动端与智能门锁 智能手表 智能安防 数据读取交互 展示 远程遥控 完美实现企业的智能终端",
            "peoBrief5": "直播 点播 视频付费 知识变现 智能流量管理 数据统计 帮助企业在互联网领域实现最好的效果呈现",
            "peoBrief6": "身份证识别 手机号码识别 征信查询 申请贷款进度查询 投资理财收益计算 分销 代还款业务",
            "peoBrief7": "企业内部培训 企业岗位识别 内部OA管理 移动化办公 视频文字学习 学习结果评测等帮助企业员工实现碎片化时间学习",
            "peoBrief8": "涉及过各行各业的移动端解决方案 根据企业需求量身定制属于你行业的解决方案",

            "caseTitle": "优秀客户案例",
            "caseMore": "查看更多",

            "partCaseText": "部分客户案例",

            "customizedText": "专业定制开发",
            "customizedName1": "我们专注于品质化高端定制开发， 企业品牌在软件项目中的整体树立，网络互动的体验，以及在手机等移动端的优质呈现。 ",
            "customizedName2": "根据客户实际情况1对1的为您提供咨询、研究、分析、策划、开发等服务，从而保障软件项目建设项能够顺利达到并超出您预期的商业目标。",
            "customizedName3": "我们专业提供一站式服务，从软件项目品牌规划、交互设计、视觉设计、动效创意，到程序开发，运营维护。",

            "customized1": "硬件项目定制",
            "customized2": "企业业务定制",
            "customized3": "网站定制",

            "top": "服务流程",
            "bg": "我们专业提供一站式服务，从网站品牌规划、视觉设计、 交互设计、 动效创意，到程序开发，运营维护。 深圳市哆啦哆科技有限公司 2013 年成立到现在 11 年的时间，我们拥有了丰富的软件开发经验和运维经验，来保证我们的工作的顺利进行。",

            "box1": "需求   评估",
            "box2": "协议",
            "box2Bou": "规划",
            "box3": "风格沟通",
            "box4": "设计",
            "box5": "设计修改/确认",
            "box6": "研发阶段",
            "box7": "测试",
            "box8": "上线",
            "box9": "售后服务",

            //ClientCase
            "titleText": "客户案例",

            "all": "全部案例",
            "distribution": "分销商城",
            "finance": "贷款金融",
            "softHardware": "硬件对接",
            "service": "生活服务",
            "customized": "高级定制",
            "government": "政府机构",
            "school": "教育培训",
            "page": "条/页",


            //ServiceItem
            "mainBusiness": "主营业务",

            "mainText1": "随移动互联网的普及，哆啦哆科技主要专注于移动互联网的定制开发，从企业的会员管理，产品销售，营销活动，业务员扩展等多个维度，利用APP、公众号、小程序等多个展现方式，为企业从营销和管理上提供强有力的支持。",
            "mainText2": "我们拥有实战多年的专业技术团队，竭力为合作伙伴在互联网及移动互联网领域提供优质、及时、全面的服务，是服务行业平以的为作的互联网金牌服商。",
            "mainText3": "我们有着运营经验丰富，行业知识专业的技术团队，竭力为合作伙伴与联网及移动互联网领域提供优质、及时全面的服务。",

            "MainText1": "会员管理",
            "MainText2": "产品销售",
            "MainText3": "营销活动",
            "MainText4": "业务扩展",

            "CustomizedText": "专业定制开发",
            "CustomizedName1": "我们专注于品质化高端定制开发， 企业品牌在软件项目中的整体树立，网络互动的体验，以及在手机等移动端的优质呈现。",
            "CustomizedName2": "根据客户实际情况1对1的为您提供咨询、研究、分析、策划、开发等服务，从而保障软件项目建设项能够顺利达到并超出您预期的商业目标。",
            "CustomizedName3": "我们专业提供一站式服务，从软件项目品牌规划、交互设计、视觉设计、动效创意，到程序开发，运营维护。",

            "Customized1": "硬件项目定制",
            "Customized2": "企业业务定制",
            "Customized3": "网站定制",

            "addTopOne1-1": "智能硬件",
            "addTopOne1-2": "户外报警器：",
            "addTopOne1-3": "2G/4G系列，微信公众号",
            "addTopOne1-4": "摄像头：",
            "addTopOne1-5": "万物精灵APP+（物联网管理平台）",
            "addTopOne1-6": "GPS设备：",
            "addTopOne1-7": "万物精灵APP+（物联网管理平台）",
            "addTopOne1-8": "4G断电开关：",
            "addTopOne1-9": "万物精灵APP+（物联网管理平台）",
            "addTopOne1-10": "远程开关：",
            "addTopOne1-11": "一米阳光（微信小程序）",

            "addTopOne2-1": "绘本系列",
            "addTopOne2-2": "点读笔：",
            "addTopOne2-3": "新熊馆 APP、点读APP、想读文化",
            "addTopOne2-4": "手写笔：",
            "addTopOne2-5": "aipen APP",
            "addTopOne2-6": "指读：",
            "addTopOne2-7": "图灵台灯设备对接",
            "addTopOne2-8": "翻读/拍读：",
            "addTopOne2-9": "听听图画小程序",
            "addTopOne2-10": "WIFI点读笔：",
            "addTopOne2-11": "幼学基地、互动乐园、名校堂",
            "addTopOne2-12": "配网小程序：",
            "addTopOne2-13": "点读互动乐园（蓝牙配网、AP配网、声波配网、二维码配网）",

            "addTopOne3-1": "律所系列",
            "addTopOne3-2": "积分考核：",
            "addTopOne3-3": "金唐积分小程序（律所内部活动、案件作业、纪律/文化等方面考核）",
            "addTopOne3-4": "案件库系统：",
            "addTopOne3-5": "金唐案件库（律所所有案件基础库、全国同类型案件数据源、同类型案件文书提取/分析等）",

            "serverFieldText": "服务领域",

            "variousText": "公众号-小程序-APP-PC版",
            "various-D": "在移动互联时代，我们得出“简单使用，精彩体验”的移动应用开发理念，创造属于无线时代的精彩互联。",

            "weiTitle": "微信应用开发",
            "weiBtn1": "微信公众号",
            "weiBtn2": "小程序",
            "weiBtn3": "蓝牙对接",
            "weiBtn4": "Wi-Fi对接",
            "weiBtn5": "微信支付",
            "weiBtn6": "微官网",
            "weiBtn7": "微会员系统",
            "weiBtn8": "绘本点读",

            "industryText": "服务行业",

            "securityText": "安全高性能的系统搭建能力",
            "securityTitle1": "以高端定制开发为主，精细而恰当的交互动效，通过服务器、数据库、客户端三方结合实现，突出品牌及产品特点和形象，",
            "securityTitle2": "让用户获得耳目一新的视听效果，带来更愉悦的用户体验，传达更具魅力、印象更加深刻。",

            "Security1": "安全加固的服务器",
            "Security2": "高性能的数据库",
            "Security3": "微信端和APP客户",
            "Security4": "H5响应式交互网站",

            "securityText1": "服务流程",
            "tit1": "我们专业提供一站式服务，从网站品牌规划、视觉设计、交互设计、动效创意，到程序开发，运营维护。深圳市哆啦哆科技有限公司 2013 年成立到现在 11 年的时间，",
            "tit2": "我们拥有了丰富的软件开发经验和运维经验，来保证我们的工作的顺利进行。",

            "purposeText": "服务宗旨",
            "purposeTitle1": "成立11年以来，哆啦哆科技一直秉承合作共赢的经营理念，成为合作伙伴值得信赖的互联网金牌服务商，我们拥有一支运营经营丰",
            "purposeTitle2": "富。行业支持专业的技术团队，竭力为合作伙伴在互联网及移动互联网领域提供优质，及时，全面的服务。",


            //AboutUs
            "TitleText": "我们是谁",
            "firm1": "深圳市哆啦哆科技有限公司，简称",
            "firm2": "哆啦哆科技。",
            "frimOne": "哆啦哆科技从事多年互联网界研究和开发，拥有强大的运营团队和技术团队，专注于高端软件项目定制、硬件物联网、微信公众号开发、微信小程序开发、",
            "frimTwo": "企业网站建设、手机APP定制开发、应用系统开发等，为企业提供全球化互联网解决方案。",

            "frimTwo1-1": "公司自创立以来，已成功为",
            "frimTwo1-2": "1000家",
            "frimTwo1-3": "以上企事业单位、政府机关提供过高端互联网服务，其中包括平安金融、深圳盐田总工会、深圳东部公交、深圳港铁、",
            "frimTwo1-4": "深圳工商银行、中执集团、第一黄金集团等等。",

            "fromThree": "对哆啦哆科技来说，客户是具有排他性、唯一性的。我们强调",
            "fromThree1": "个性定制化",
            "fromThree2": "的方式与您沟通，通过创意、新技术新媒体的结合运用，为您提供整合的全行业解决方案与全方位的开发服务。哆啦哆科技洞悉中国互联网发展轨迹的同时，以最为专业的互联网新思维去深入思考软件项目",
            "fromThree3": "在中国企业、中国互联网运营商以及网民中的真正价值，坚定不移的扛起中国网络创新的大旗，致力于揭开网络应用的新篇章！",

            "TitleText1": "企业优点",
            "businessArr1": "量身打造",
            "businessArr2": "领先设计",
            "businessArr3": "全网开发",
            "Brief1": "针对不同企业状况、客户特殊需求等量身打造，从专业化的软件方案定制，独一无二的视觉效果设计，到易于二次开发的规范化代码编写，易于操作的人性化管理后台，无不体现“定制”的意义",
            "Brief2": "为客户定制适合自己的互联网形象，尊重访问者视觉体验和浏览习惯，用户体验友好，注重信息架构分析，界面设计、互动设计和内容制作，为客户提供高价值的平台。",
            "Brief3": "全网是一个新概念，是互联网时代的必然产物，更是企业在未来几年内的生存之道。哆啦哆科技为客户提供全方位全网开发。微信公众平台开发、小程序开发、APP开发、网站建设、微站设计、硬件对接，同时完整的将互联网各平台结合，实现一站式管理。",

            "TitleText2": "我们的团队",

            "teamBo1": "商务策划",
            "teamBo2": "不只是开发，更提供有价值的思路和整体服务。",
            "teamBo3": "哆啦哆科技不断深入对各类行业属性以及商业模式的探索，全面了解企业现状、深入挖掘品牌价值、专业策划并准确全是产品价值。",
            "teamBo4": "项目执行",
            "teamBo5": "主要负责软件开发项目的开发管理和流程监控工作",
            "teamBo6": "哆啦哆科技的整个项目组对工作是非常细致严谨的，项目进行中随时与客户保持商量和沟通，双方配合在最短的时间内顺利完成项目开发工作。",
            "teamBo7": "设计师",
            "teamBo8": "我们喜爱创新，对创意设计有着极高的热情，并准备着为一个好的点子通宵达旦。",
            "teamBo9": "我们不仅提供漂亮的设计，更知道设计是一种方法或策略，而非仅仅是美术自由，设计载体是品牌，强调设计的实用性",
            "teamBo10": "Web前端工程师",
            "teamBo11": "根据已确认的设计稿进行每一个页面的制作。",
            "teamBo12": "我们会按照我们的W3C制作标准进行web开发，使得更简易、可方便调节、各浏览器兼容性强、可扩展等，让用户体验达到一定高度！",
            "teamBo13": "我们将以最大程度上减少页面代码，使爬行蜘蛛能带走更多有用有价值的信息。并且我们将严格遵守标准CSS命名规则，使搜索引擎能够快速的认知网页内容板块结构，快速收录！",


            //ContactItem
            "titleText2": "联系我们",
            "icName1": "罗先生 138 2875 5858",
            "icName2": "深圳市南山区粤海街道园西工业区23栋北侧2楼216",
            "mapName": "深圳市哆啦哆科技有限公司",
            "end": "确认",
            "placeholder1": "请输入起点地址",
            "placeholder2": "南山区粤海街道园西工业区23栋北侧",



            //PartCase
            "caseBack": "返回案例列表",


        },








        
        //英文翻译包
        'en': {
            // 首页
            "logoPhone": "Doradoar Technology",
            "logoPhoneText": "Shenzhen Doradoar Technology Co., Ltd",

            "index": "Home",
            "ClientCase": "Client Case",
            "ServiceItem": "Service Item",
            "AboutUs": "About Us",
            "ContactItem": "Contact Us",
            "MrLuo": "Mr. Luo",
            "Language": "Language",
            "Language2": "Switch Language",

            "bannerTitle": "Software Customization",
            "bannerContent": "Assist enterprises digitally transform and upgrade",

            "sidebox": "Consultation",

            "fd-1-1": "About Us",
            "fd-1-2": "Who are we",
            "fd-1-3": "Clients we serve",
            "fd-1-4": "Our team",

            "fd-2-1": "Our Services",
            "fd-2-2": "Website Construction",
            "fd-2-3": "H5 Responsive Interactive Website",
            "fd-2-4": "Mobile&Micro Website Customization",
            "fd-2-5": "Service Process",
            "fd-2-6": "Industry Solutions",

            "fd-3-1": "Our case",
            "fd-3-2": "Bluetooth device docking",
            "fd-3-3": "Wi-Fi device docking",
            "fd-3-4": "Outdoor alarm customization",
            "fd-3-5": "Law Firm Points System",
            "fd-3-6": "Picture Book Reading Series",
            "fd-3-7": "digital agriculture",
            "fd-3-8": "Distribution Mall",
            "fd-3-9": "Loan finance",
            "fd-3-10": "Hardware docking",
            "fd-3-11": "Life Services",
            "fd-3-12": "Advanced customization",
            "fd-3-13": "Government agencies",
            "fd-3-14": "Education and Training",

            "fd-4-1": "Shenzhen Doradoar Technology Co., Ltd",
            "fd-4-2": "Technical Lead:",
            "fd-4-3": "Scan to learn more",
            "fd-4-4": " Copyright © 2013-2024 Shenzhen Doradoar Technology Co., Ltd. All rights reserved. Guangdong ICP No. 2024261444",

            "fd-4-5": "Copyright©2013-2024",
            "fd-4-6": "Shenzhen Doradoar Technology Co., Ltd. All rights reserved",
            "fd-4-7": "粤ICP备2024261444号",



            //HomePage
            "1": "Internet customization",
            "2": "APP",
            "3": "Mini program",
            "4": "website",
            "5": "WP website customization",
            "6": "Bluetooth battery",
            "7": "Bluetooth remote control",
            "8": "Bluetooth reading pen",
            "9": "Alarm equipment",
            "10": "learning machine",
            "11": "wand",
            "12": "Handwriting pen",
            "13": "Picture book reading",
            "14": "Picture book flipping",

            "Main business": "Main business",

            "bussiness1": "Customized development of mobile APP",
            "bussiness2": "Mini Program industry solutions",
            "bussiness3": "WeChat public platform development",
            "bussiness4": "Bluetooth /Wi-Fi / hardware docking",

            "brief1": "Support IOS and Android development can customize the software layout and functions, and the application market is on the shelves",
            "brief2": "It’s dedicated to aiding SMEs in integrating online and offline operations and achieving digital transformation via the Internet.",
            "brief3": "Support the development of enterprise office, equipment management and other systems",
            "brief4": "Bluetooth hardware, alarm devices, children's picture books",

            "bussinessBottom1": "Official account / Applet / APP / Bluetooth / Wi-Fi / Hardware docking",
            "bussinessBottom2": "Focus on customized development for enterprises; Breaking the bottleneck of enterprise development; Assist enterprises in digital transformation and upgrading",
            "bussinessBottom3": "Learn more",

            "pro1": "Mobile mall solutions",
            "pro2": "Marketing campaign software solutions",
            "pro3": "Online booking and lesson purchase solution",
            "pro4": "Software and hardware interaction solutions",
            "pro5": "Live video course solution",
            "pro6": "Internet financial solutions",
            "pro7": "Enterprise Training Solutions",
            "pro8": "More Solutions",

            "peoBrief1": "WeChat Mall, Mini Program Mall, Distribution Mall, National Agent City, Personalized Diversification and other malls Professional and complete solutions",
            "peoBrief2": "Common and customized marketing software such as grouping, bargaining, answering questions, red envelopes, scratching prizes, coupons, and voting make the company's system more powerful",
            "peoBrief3": "Beauty and Fitness Course Training Sheet/Multi Store One Stop Purchase Package Appointment Mentor/Technician/Teacher Tailored Mobile User Software Based on Enterprise Workflow",
            "peoBrief4": "Beauty Fitness Course Training Single/Multi-Store One-stop Purchase Packages Book an Appointment with a Tutor/Technician/Teacher Tailor the mobile user software to your business workflow",
            "peoBrief5": "Live video on demand payment knowledge realization intelligent traffic management data statistics help enterprises achieve the best results in the Internet field",
            "peoBrief6": "ID card recognition, mobile phone number recognition, credit inquiry, loan application progress inquiry, investment and wealth management income calculation, distribution and repayment service",
            "peoBrief7": "Internal training, job identification, internal OA management, mobile office, video and text learning, evaluation of learning results, etc. to help employees achieve fragmented time learning",
            "peoBrief8": "Tailored solutions for mobile devices across various industries based on enterprise needs, tailored to your specific industry",

            "caseTitle": "Excellent Customer Cases",
            "caseMore": "View more",

            "partCaseText": "Case of us",

            "customizedText": "Professional customized development",
            "customizedName1": "We focus on high-quality high-end customized development, overall establishment of enterprise brand in software projects, online interactive experience, and high-quality presentation on mobile devices such as smartphones",
            "customizedName2": "We provide one-on-one consultation, research, analysis, planning, development and other services based on the actual situation of the client, in order to ensure that the software project construction project can smoothly achieve and exceed your expected business goals",
            "customizedName3": "We specialize in providing one-stop services, from software project brand planning, interaction design, visual design, animation creativity, to program development, operation and maintenance",

            "customized1": "Hardware Project customization",
            "customized2": "Enterprise Business Customization",
            "customized3": "Website Customization",

            "top": "SERVICE PROCESS",
            "bg": "We specialize in providing one-stop services, from website brand planning, visual design, interaction design, animation creativity, to program development, operation and maintenance.Doradoar Technology Co., Ltd. Shenzhen city has been established for 11 years since 2013. We have rich experience in software development and operation to ensure the smooth progress of our work",

            "box1": "Requirements   Estimate",
            "box2": "Agreement",
            "box2Bou": "Planning",
            "box3": "Style Communication",
            "box4": "Design",
            "box5": "Design Modification",
            "box6": "Development Phase",
            "box7": "Test",
            "box8": "Online",
            "box9": "After-sale Service",

            //ClientCase
            "titleText": "ClientCase",

            "all": "All",
            "distribution": "Distribution",
            "finance": "Finance",
            "softHardware": "Hardware",
            "service": "Service",
            "customized": "Customized",
            "government": "Government",
            "school": "Education",
            "page": "pieces/page",


            //ServiceItem
            "mainBusiness": "Main business",

            "mainText1": "With the popularization of mobile Internet, Dorado Technology mainly focuses on the customized development of mobile Internet, providing strong support for enterprises in terms of marketing and management from multiple dimensions, such as member management, product sales, marketing activities, salesperson expansion, etc., by using multiple presentation methods, such as APP, official account, and applet.",
            "mainText2": "We have a professional technical team that has been fighting for many years. We try our best to provide our partners with high-quality, timely and comprehensive services in the field of Internet and mobile Internet. We are a gold medal Internet service provider in the service industry.",
            "mainText3": "We have a technical team with rich operation experience and professional industry knowledge, and strive to provide high-quality, timely and comprehensive services for partners, networking and mobile Internet fields.",

            "MainText1": "Member Management",
            "MainText2": "Product Sales",
            "MainText3": "Marketing Activities",
            "MainText4": "Business Expansion",

            "CustomizedText": "Professional Custom Design",
            "CustomizedName1": "We focus on high-quality high-end customized development, overall establishment of enterprise brand in software projects, online interactive experience, and high-quality presentation on mobile devices such as smartphones.",
            "CustomizedName2": "We provide one-on-one consultation, research, analysis, planning, development and other services based on the actual situation of the client, in order to ensure that the software project construction project can smoothly achieve and exceed your expected business goals.",
            "CustomizedName3": "We specialize in providing one-stop services, from software project brand planning, interaction design, visual design, animation creativity, to program development, operation and maintenance.",

            "Customized1": "Hardware Project Customization",
            "Customized2": "Enterprise Business Customization",
            "Customized3": "Website Customization",

            "addTopOne1-1": "Intelligent hardware",
            "addTopOne1-2": "Outdoor alarm:",
            "addTopOne1-3": "2G/4G series, WeChat official account",
            "addTopOne1-4": "Camera:",
            "addTopOne1-5": "WanWu Genie APP+(Internet of Things Management Platform)",
            "addTopOne1-6": "GPS device:",
            "addTopOne1-7": "WanWu Genie APP+(Internet of Things Management Platform)",
            "addTopOne1-8": "4G power off switch:",
            "addTopOne1-9": "WanWu Genie APP+(Internet of Things Management Platform)",
            "addTopOne1-10": "Remote switch:",
            "addTopOne1-11": "One meter sunshine (WeChat mini program)",

            "addTopOne2-1": "Picture book series",
            "addTopOne2-2": "Reading pen:",
            "addTopOne2-3": "New Bear Pavilion APP, Click to Read APP, Want to Read Culture",
            "addTopOne2-4": "Handwriting pen:",
            "addTopOne2-5": "aipen APP",
            "addTopOne2-6": "Finger reading:",
            "addTopOne2-7": "Turing desk lamp equipment docking",
            "addTopOne2-8": "Flipping/Flipping:",
            "addTopOne2-9": "Listen to the picture mini program",
            "addTopOne2-10": "WIFI reading pen:",
            "addTopOne2-11": "Preschool school base, interactive amusement park, prestigious school hall",
            "addTopOne2-12": "Distribution network mini program:",
            "addTopOne2-13": "Click to read interactive amusement park (Bluetooth distribution network, AP distribution network, sound wave distribution network, QR code distribution network)",

            "addTopOne3-1": "Law Firm Series",
            "addTopOne3-2": "Integral assessment:",
            "addTopOne3-3": "Jintang Points Mini Program (assessment of internal activities, case assignments, discipline/culture, etc. within the law firm)",
            "addTopOne3-4": "Case database system:",
            "addTopOne3-5": "Jintang Case Database (including the basic database of all cases in the law firm, national data sources of similar cases, and extraction/analysis of documents related to similar cases)",

            "serverFieldText": "Service Area",

            "variousText": "Official account - applet - APP-PC version",
            "various-D": "In the era of mobile internet, we have developed the concept of 'simple use, wonderful experience' for mobile application development, creating a wonderful internet belonging to the wireless era.",


            "weiTitle": "WeChat application development",
            "weiBtn1": "WeChat official account",
            "weiBtn2": "Applet",
            "weiBtn3": "Bluetooth docking",
            "weiBtn4": "Wi-Fi docking",
            "weiBtn5": "WeChat Pay",
            "weiBtn6": "WeChat official website",
            "weiBtn7": "Micro membership system",
            "weiBtn8": "Picture book reading",

            "industryText": "Service Industry",

            "securityText": "Safe and High-Performance System",
            "securityTitle1": "Focusing on high-end customized development, with precise and appropriate interactive effects, achieved through the combination of servers, databases, and clients, highlighting the brand and product characteristics and image,",
            "securityTitle2": "Provide users with refreshing audio-visual effects, bring a more pleasant user experience, convey more charm, and leave a deeper impression.",

            "Security1": "Safely reinforced servers",
            "Security2": "High performance database",
            "Security3": "WeChat and APP customers",
            "Security4": "H5 responsive interactive website",

            "securityText1": "Service Process",
            "tit1": "We specialize in providing one-stop services, from website brand planning, visual design, interaction design, animation creativity, to program development, operation and maintenance. Shenzhen Doradoar Technology Co., Ltd. has been established for 11 years since 2013,",
            "tit2": "We have extensive experience in software development and operations to ensure the smooth progress of our work.",

            "purposeText": "Service Purposes",
            "purposeTitle1": "Since its establishment 11 years ago, Duoladuo Technology has always adhered to the business philosophy of win-win cooperation and become a trustworthy Internet gold service provider for partners. We have a branch with rich operations.",
            "purposeTitle2": "The industry supports a professional technical team and strives to provide partners with high-quality, timely and comprehensive services in the field of Internet and mobile Internet.",


            //AboutUs
            "TitleText": "Who are we",
            "firm1": "Shenzhen Dorado Technology Co., Ltd., abbreviated as",
            "firm2": "Doradoar Technology.",
            "frimOne": "Doradoar Technology has been engaged in Internet research and development for many years. It has a strong operation team and technical team, focusing on high-end software project customization, hardware Internet of Things, WeChat official account development, WeChat applet development",
            "frimTwo": "Enterprise website construction, mobile APP customization development, application system development, etc., to provide enterprises with global Internet solutions.",

            "frimTwo1-1": "Since its inception, the company has successfully provided high-end Internet services to more than ",
            "frimTwo1-2": "1000",
            "frimTwo1-3": "enterprises and institutions, and government agencies, including Ping An Finance, Shenzhen Yantian Federation of Trade Unions, Shenzhen East Bus, Shenzhen Port Railway",
            "frimTwo1-4": "Shenzhen Industrial and Commercial Bank, Zhongzhi Group, First Gold Group, and so on.",

            "fromThree": "For Doradoar Technology, customers are exclusive and unique. We emphasize",
            "fromThree1": "Personalized customization",
            "fromThree2": "We communicate with you through a combination of creativity, new technologies, and new media, providing you with integrated industry wide solutions and comprehensive development services. Dorado Technology understands the development track of China's Internet, and at the same time, it deeply considers software projects with the most professional new Internet thinking",
            "fromThree3": "The real value in Chinese enterprises, Chinese Internet operators and netizens, unswervingly carry the banner of Chinese network innovation, and are committed to opening a new chapter in network applications!",

            "TitleText1": "Enterprise Advantages",
            "businessArr1": "Tailor-made",
            "businessArr2": "Leading design",
            "businessArr3": "Network-wide development",
            "Brief1": "Tailored for different enterprise conditions and special needs of customers, from professional software program customization, unique visual effect design, to standardized code writing that is easy to re-develop, and humanized management background that is easy to operate, all reflect the meaning of 'customization'.",
            "Brief2": "Customize the Internet image suitable for customers, respect the visual experience and browsing habits of visitors, user experience is friendly, pay attention to information architecture analysis, interface design, interactive design and content production, and provide customers with a high-value platform.",
            "Brief3": "The whole network is an inevitable and new product of the Internet era, and a way for enterprises to survive in the next few years. Doradora Technology provides customers with a full range of network-wide development. The complete integration of Internet platforms to achieve one-stop management.",

            "TitleText2": "Our Team",

            "teamBo1": "Business planning",
            "teamBo2": "Not only development, but also provide valuable ideas and overall services.",
            "teamBo3": "Doradora Technology continues to explore various industry attributes and business models, comprehensively understand the current situation of the enterprise, dig deep into the brand value, professional planning and accurate product value.",
            "teamBo4": "Project execution",
            "teamBo5": "Mainly responsible for the development management and process monitoring of software development projects",
            "teamBo6": "The entire project team of Doradore Technology is very meticulous and rigorous in its work, and maintains consultation and communication with customers at any time during the project, and the two sides cooperate to successfully complete the project development work in the shortest possible time.",
            "teamBo7": "Designer",
            "teamBo8": "We love innovation, have a passion for creative design, and are ready to stay up all night with a great idea.",
            "teamBo9": "We not only provide beautiful design, but also know that design is a method or strategy, not just artistic freedom, and the design carrier is the brand, emphasizing the practicality of design",
            "teamBo10": "Web Front-End Engineer",
            "teamBo11": "Create each page based on the confirmed design draft.",
            "teamBo12": "We will carry out web development in accordance with our W3C production standards, making it simpler, easy to adjust, more compatible with various browsers, extensible, etc., so that the user experience can reach a certain height!",
            "teamBo13": "We're going to minimize the code on the page, so that crawling spiders can take away more useful and valuable information. And we will strictly abide by the standard CSS naming rules, so that search engines can quickly recognize the structure of web content sections and quickly include them!",


            //ContactItem
            "titleText2": "Contact us",
            "icName1": "Mr. Luo 138 2875 5858",
            "icName2": "216, 2nd Floor, North Building, Building 23, Yuanxi Industrial Zone, Yuehai Street, Nanshan District, Shenzhen",
            "mapName": "Shenzhen Doradoar Technology Co., Ltd",
            "end": "confirm",
            "placeholder1": "Please enter the starting point address",
            "placeholder2": "Building 23 in the West Industrial Zone",



            //PartCase
            "caseBack": "Return to the list of cases",







        }
    },

})



export default i18n

