 import Vue from 'vue'
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map';
import VueI18n from './i18n';
 
Vue.use(BaiduMap, {
    ak: 'igwc8gaORvfT13AEqhwihu3FEijgTKUR'
});
Vue.use(ElementUI);
Vue.use(VueI18n); //挂载i18n实例

Vue.config.productionTip = false;
new Vue({
  router, // 将 router 对象传递给 Vue 实例
  i18n: VueI18n, // 将 i18n 对象传递给 Vue 实例
  render: h => h(App),
}).$mount('#app');